.tabnav {
  text-decoration: none !important;
}
.img {
  text-align: center;
}
.txtwhite {
  color: #ffffff;
}
.clr {
  height: 100px;
  width: 100px;
  border: 5px solid brown;
  border-radius: 100%;
  background-color: aqua;
}
